import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Importing the date formatting utility
import { format } from 'date-fns';

const Reports2 = () => {
  const [histories, setHistories] = useState([]);
  const [filteredHistories, setFilteredHistories] = useState([]);
  const [currencies, setCurrencies] = useState({});
  const [clients, setClients] = useState({}); // State to store clients data
  const [beneficiaries, setBeneficiaries] = useState({}); // State to store beneficiaries data
  const [agentId, setAgentID] = useState('')
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [totals, setTotals] = useState({ usd: 0, rands: 0, zig: 0, pulla: 0 }); // State for totals
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Function to fetch agent histories
  const fetchHistories = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Agent/histories', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
        },
      });
      setHistories(response.data.history);
      setFilteredHistories(response.data.history); // Set filtered histories to all histories initially
    } catch (err) {
      setError('Failed to fetch histories. Please try again later.');
    }
  };

  // Function to fetch currencies
  const fetchCurrencies = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
        },
      });

      const currencyData = response.data.data.reduce((acc, currency) => {
        acc[currency.id] = currency.name.toLowerCase(); // Store currency name in lowercase
        return acc;
      }, {});

      setCurrencies(currencyData);
    } catch (err) {
      setError('Failed to fetch currencies. Please try again later.');
    }
  };

  // Function to fetch clients
  const fetchClients = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Agent/clients', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
        },
      });

      const clientData = response.data.data.reduce((acc, client) => {
        acc[client.id] = `${client.name} ${client.surname}`; // Store client full name
        return acc;
      }, {});

      setClients(clientData);
    } catch (err) {
      setError('Failed to fetch clients. Please try again later.');
    }
  };

  // Function to fetch beneficiaries for a specific client
  const fetchBeneficiaries = async () => {
    try {
      const response = await axios.get(`https://api.nkolozi.com/api/Agent/get-beneficiaries`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
        },
      });

      const beneficiaryData = {};
      response.data.beneficiaries.forEach(beneficiary => {
        beneficiaryData[beneficiary.id] = beneficiary.full_name;
      });

      setBeneficiaries(beneficiaryData);
    } catch (err) {
      setError('Failed to fetch beneficiaries. Please try again later.');
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchCurrencies();
      await fetchClients();
      await fetchBeneficiaries(); // This will fetch all beneficiaries
      await fetchHistories();

      setLoading(false);
    };

    async function fetchAgent(){
      try {
        
        const response = await axios.get('https://api.nkolozi.com/api/Agent/agent', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
          },
        })
        setAgentID(response.data.data)
        console.log(response.data.data)
       
      } catch (error) {
        console.log(error)
      }
    }
    fetchAgent()

    fetchData();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    applyFilters(e.target.value, startDate, endDate);
  };

  // Function to handle date filter changes
  const handleDateFilterChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    applyFilters(searchTerm, start, end);
  };

  // Function to clear date filters
  const clearDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
    applyFilters(searchTerm, null, null);
  };

  // Function to apply all filters (search term and date range)
  const applyFilters = (term, start, end) => {
    let filtered = [...histories];
    
    // Apply search term filter
    if (term) {
      filtered = filtered.filter(history => 
        history.client_id.toString().includes(term)
      );
    }
    
    // Apply date range filter
    if (start && end) {
      filtered = filtered.filter(history => {
        const historyDate = new Date(history.created_at);
        return historyDate >= start && historyDate <= end;
      });
    }
    
    setFilteredHistories(filtered);
  };

  // Calculate totals based on filtered histories
  useEffect(() => {
    const newTotals = { usd: 0, rands: 0, zig: 0, pulla: 0 };

    filteredHistories.forEach(history => {
      const currencyName = currencies[history.currency_id];
      const amount = parseFloat(history.amount);

      switch (currencyName) {
        case 'usd':
          newTotals.usd += amount;
          break;
        case 'rands':
          newTotals.rands += amount;
          break;
        case 'zig':
          newTotals.zig += amount;
          break;
        case 'pulla':
          newTotals.pulla += amount;
          break;
        default:
          break;
      }
    });

    setTotals(newTotals);
  }, [filteredHistories, currencies]);

  // Function to format date
  const formatDate = (dateString) => {
    return format(new Date(dateString), 'd MMMM yyyy');
  };

  // Function to convert to PDF
  const handleConvertToPDF = () => {
    const doc = new jsPDF();
    doc.text('Agent Histories', 20, 10);
    autoTable(doc, {
      head: [['ID', 'User ID', 'Agent ID', 'Client Name', 'Beneficiary', 'Currency', 'Amount', 'Type', 'Created At', 'Updated At']],
      body: filteredHistories.map(history => [
        history.id,
        history.user_id,
        history.agent_id,
        clients[history.client_id] || 'Unknown',
        beneficiaries[history.beneficiary_id] || 'Unknown',
        currencies[history.currency_id] || 'Unknown',
        history.amount,
        history.type,
        formatDate(history.created_at),
        formatDate(history.updated_at)
      ]),
    });

    // Add totals section
    autoTable(doc, {
      head: [['Currency', 'Total Amount']],
      body: [
        ['USD', totals.usd.toFixed(2)],
        ['Rands', totals.rands.toFixed(2)],
        ['Zig', totals.zig.toFixed(2)],
        ['Pulla', totals.pulla.toFixed(2)],
      ],
      startY: doc.lastAutoTable.finalY + 10,
    });

    doc.save('Agent_Histories.pdf');
  };

  // Function to convert to Excel
  const handleConvertToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredHistories.map(history => ({
      ID: history.id,
      User_ID: history.user_id,
      Agent_ID: history.agent_id,
      Client_Name: clients[history.client_id] || 'Unknown',
      Beneficiary: beneficiaries[history.beneficiary_id] || 'Unknown',
      Currency: currencies[history.currency_id] || 'Unknown',
      Amount: history.amount,
      Type: history.type,
      Created_At: formatDate(history.created_at),
      Updated_At: formatDate(history.updated_at)
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Agent Histories');

    // Add totals section
    const totalsWorksheet = XLSX.utils.json_to_sheet([
      { Currency: 'USD', Total_Amount: totals.usd.toFixed(2) },
      { Currency: 'Rands', Total_Amount: totals.rands.toFixed(2) },
      { Currency: 'Zig', Total_Amount: totals.zig.toFixed(2) },
      { Currency: 'Pulla', Total_Amount: totals.pulla.toFixed(2) },
    ]);
    XLSX.utils.book_append_sheet(workbook, totalsWorksheet, 'Totals');

    XLSX.writeFile(workbook, 'Agent_Histories.xlsx');
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center my-4">
        <h2>Agent Histories</h2>
        <div>
          <button className="btn btn-primary mr-2" onClick={handleConvertToPDF}>Convert to PDF</button>
          <button className="btn btn-success" onClick={handleConvertToExcel}>Convert to Excel</button>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="search">Search by Client ID:</label>
            <input
              type="text"
              className="form-control"
              id="search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Enter Client ID to search"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Filter by Date Range:</label>
            <div className="d-flex">
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateFilterChange}
                className="form-control"
                placeholderText="Select date range"
                dateFormat="yyyy-MM-dd"
              />
              <button 
                className="btn btn-outline-secondary ml-2" 
                onClick={clearDateFilters}
                disabled={!startDate && !endDate}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <>
          <table className="table table-bordered">
            <thead>
              <tr>
              
                <th>User </th>
                <th>Agent </th>
                <th>Client Name</th>
                <th>Beneficiary</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {filteredHistories.map(history => (
                <tr key={history.id}>
                 
                  <td>{agentId.name}{agentId.surname}</td>
                  <td>{agentId.name} {agentId.surname}</td>
                  <td>{clients[history.client_id] || 'Unknown'}</td>
                  <td>{beneficiaries[history.beneficiary_id] || 'Unknown'}</td>
                  <td>{currencies[history.currency_id] || 'Unknown'}</td>
                  <td>{history.amount}</td>
                  <td>{history.type}</td>
                  <td>{formatDate(history.created_at)}</td>
                  <td>{formatDate(history.updated_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-4">
            <h4>Totals:</h4>
            <ul>
              <li>USD: {totals.usd.toFixed(2)}</li>
              <li>Rands: {totals.rands.toFixed(2)}</li>
              <li>Zig: {totals.zig.toFixed(2)}</li>
              <li>Pulla: {totals.pulla.toFixed(2)}</li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Reports2;
